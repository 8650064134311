import { contextBuilder } from '@appbuckets/react-ui-core';

import type { UserScopeContext } from './UserScopeProvider.types';


/* --------
 * Context Builder
 * -------- */
const {
  hook    : useUserScope,
  Provider: BaseUserScopeProvider
} = contextBuilder<UserScopeContext>(undefined, 'UserScopeContext');


/* --------
 * Context Exports
 * -------- */
export { useUserScope, BaseUserScopeProvider };
