import * as React from 'react';

import Container from '@appbuckets/react-ui/Container';

import { AppRouter } from '@appbuckets/app-router';
import { useClientState } from '@appbuckets/react-app-client';

import { useUserScope } from './init/UserScopeProvider';

import routes from './router/routes';
import * as RouteComponents from './router/components';

import type { RoutesDefinition } from './router/routes-definition';

import SpidAuthError from './ui/SpidAuthError';
import EmailModal from './components/EmailModal/EmailModal';

/* --------
 * App Definition
 * -------- */
const ReuseBoardApp: React.FunctionComponent = () => {

  // ----
  // Internal State
  // ----
  const { hasAuth } = useClientState();
  const { userScopeReady } = useUserScope();


  // ----
  // Component Render
  // ----
  return (
    <Container fixedTo={'desktop'}>
      <AppRouter<RoutesDefinition>
        useRouteClassName
        routes={routes as any}
        defaultAppName={'La Bacheca del Riuso'}
        hidePageWhileInitiallyLoading={true}
        hidePageWhileLoading={false}
        pageTitleWhileInitiallyLoading={'Caricamento...'}
        hasNavbar={true}
        hasFooter={!!userScopeReady}
        hasSidebar={false}
        userHasAuth={hasAuth}
        isInitiallyLoading={!userScopeReady}
        Components={{
          InitialLoader: RouteComponents.FullPageLoader,
          Navbar       : RouteComponents.Navbar as any,
          Footer       : RouteComponents.Footer
        }}
      />
      <EmailModal />
      <SpidAuthError />
    </Container>
  );

};

ReuseBoardApp.displayName = 'ReuseBoardApp';

export default ReuseBoardApp;
