import { useQuery } from 'react-query';
import type { UseQueryResult, UseQueryOptions } from 'react-query';

import type { ClientRequestError, ClientRequestParams } from '@appbuckets/react-app-client';


/* --------
 * Internal Types
 * -------- */
interface QuerySystemKey<QueryObject> {
  /** Change the entity filter logic */
  $filterLogic?: 'and' | 'or';

  /** Set an array of field to include */
  $include?: (keyof QueryObject | string)[];

  /** Set an array of field to select */
  $select?: (keyof QueryObject)[];

  /** Set the number of record to skip */
  $skip?: number;

  /** Set query sorting */
  $sort?: string[];

  /** Set the number of record to get */
  $take?: number;
}


type UseGetQueryParams<QueryObject> =
  | { [key: string]: string | number }
  | QuerySystemKey<QueryObject>
  | Partial<Record<keyof QueryObject, string | number>>;


type UseGetQueryConfig<QueryObject> =
  & Omit<ClientRequestParams, 'params'>
  & { params?: UseGetQueryParams<QueryObject> };


/* --------
 * Query Definition
 * -------- */
export function useGetQuery<Result, QueryObject = Result>(
  queryPath: string[],
  queryConfig?: UseGetQueryConfig<QueryObject>,
  options?: Omit<UseQueryOptions<Result, ClientRequestError, Result>, 'queryKey'>
): UseQueryResult<Result, ClientRequestError> {

  // ----
  // Params Preparation
  // ----
  const queryKeys: any[] = [ ...queryPath ];

  if (queryConfig) {
    /** Skip user defined params from query config */
    const {
      params: userDefinedParams,
      ...userDefinedConfig
    } = queryConfig;

    /** Prepare the queryOptions */
    const queryOptions: ClientRequestParams = { ...userDefinedConfig };

    /** Check if there are some params to get */
    if (userDefinedParams) {
      queryOptions.params = {};

      /** Loop each key to assert value is a string */
      Object.keys(userDefinedParams).forEach((key) => {
        // @ts-ignore
        const value = userDefinedParams[key];

        /** Join if value is an array */
        if (Array.isArray(value)) {
          queryOptions.params![key] = value.join(',');
        }
        else {
          queryOptions.params![key] = value;
        }
      });
    }

    /** Add query options to keys */
    queryKeys.push(queryOptions);
  }


  // ----
  // Hook Return
  // ----
  return useQuery<Result, ClientRequestError>(queryKeys, options);

}
