import * as React from 'react';

import { useAutoControlledValue } from '@appbuckets/react-ui-core';


/* --------
 * Internal Types
 * -------- */
interface UsePaginationOptions {
  /** The number of elements visible on left/right edges, defaults to 1  */
  boundaries?: number;

  /** The initially selected page */
  initialPage?: number;

  /** Watch for page change */
  onChange?: (page: number) => void;

  /** Control the Page */
  page?: number;

  /** Count of siblings page to show */
  siblings?: number;

  /** The total number of pages */
  total: number;
}

export interface UsePaginationReturn {
  /** The current active page */
  active: number;

  /** Helper to go to the first page */
  first: (restoreScroll?: boolean) => void;

  /** Helper to go to the last page */
  last: (restoreScroll?: boolean) => void;

  /** Helper to increase the page by 1 */
  next: (restoreScroll?: boolean) => void;

  /** Helper to decrease the page by 1 */
  previous: (restoreScroll?: boolean) => void;

  /** The pagination range to use to render component */
  range: (number | typeof DOTS)[];

  /** Helper to set the current active page */
  setPage: (page: number, restoreScroll?: boolean) => void;
}


/* --------
 * Internal Helpers
 * -------- */
function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
}

const DOTS = 'dots';


/* --------
 * Hook Definition
 * -------- */
export function usePagination({
  boundaries = 1,
  initialPage,
  siblings = 1,
  total   : _total,
  page    : userDefinedPage,
  onChange: userDefinedOnChange
}: UsePaginationOptions): UsePaginationReturn {

  /** Assert total pages is a valid positive number */
  const total = Math.max(Math.trunc(_total), 0);

  /** Internal hook for the current selected page */
  const [ activePage, trySetActivePage ] = useAutoControlledValue(1, {
    defaultProp: initialPage,
    prop       : userDefinedPage
  });

  // ----
  // Helpers
  // ----
  const setPage = React.useCallback(
    (page: number, restoreScroll?: boolean) => {
      /** Get the next page */
      let nextPage: number = page;

      /** Assert the page is not negative */
      if (nextPage <= 0) {
        nextPage = 1;
      }
      /** Assert the page is in the range of total pages */
      else if (nextPage > total) {
        nextPage = total;
      }

      /** Update the internal state */
      trySetActivePage(nextPage);

      /** Call the user defined handler */
      if (typeof userDefinedOnChange === 'function') {
        userDefinedOnChange(nextPage);
      }

      /** Restore the scroll position if required */
      if (restoreScroll) {
        window.scrollTo({
          top     : 0,
          behavior: 'smooth'
        });
      }
    },
    [ total, trySetActivePage, userDefinedOnChange ]
  );

  const next = React.useCallback(
    (restoreScroll?: boolean) => setPage(activePage + 1, restoreScroll),
    [ setPage, activePage ]
  );

  const previous = React.useCallback(
    (restoreScroll?: boolean) => setPage(activePage - 1, restoreScroll),
    [ setPage, activePage ]
  );

  const first = React.useCallback(
    (restoreScroll?: boolean) => setPage(1, restoreScroll),
    [ setPage ]
  );

  const last = React.useCallback(
    (restoreScroll?: boolean) => setPage(Number.MAX_SAFE_INTEGER, restoreScroll),
    [ setPage ]
  );

  // ----
  // Memoized Data
  // ----
  const paginationRange = React.useMemo(
    (): (number | typeof DOTS)[] => {
      const totalPageNumbers = siblings * 2 + 3 + boundaries * 2;
      if (totalPageNumbers >= total) {
        return range(1, total);
      }

      const leftSiblingIndex = Math.max(activePage - siblings, boundaries);
      const rightSiblingIndex = Math.min(activePage + siblings, total - boundaries);

      const shouldShowLeftDots = leftSiblingIndex > boundaries + 2;
      const shouldShowRightDots = rightSiblingIndex < total - (boundaries + 1);

      if (!shouldShowLeftDots && shouldShowRightDots) {
        const leftItemCount = siblings * 2 + boundaries + 2;
        return [ ...range(1, leftItemCount), DOTS, ...range(total - (boundaries - 1), total) ];
      }

      if (shouldShowLeftDots && !shouldShowRightDots) {
        const rightItemCount = boundaries + 1 + 2 * siblings;
        return [ ...range(1, boundaries), DOTS, ...range(total - rightItemCount, total) ];
      }

      return [
        ...range(1, boundaries),
        DOTS,
        ...range(leftSiblingIndex, rightSiblingIndex),
        DOTS,
        ...range(total - boundaries + 1, total)
      ];
    },
    [ total, siblings, activePage, boundaries ]
  );


  // ----
  // Hook return
  // ----
  return {
    range : paginationRange,
    active: activePage,
    setPage,
    next,
    previous,
    first,
    last
  };
}
