import * as React from 'react';
import Modal from '@appbuckets/react-ui/Modal';


/* --------
 * Error Parsing
 * -------- */
const searchParams = new URLSearchParams(window.location.search);
const hasSpidLoginError = searchParams.get('spid-login') === 'error' && searchParams.has('error');
const spidErrorMessage = hasSpidLoginError
  ? decodeURIComponent(searchParams.get('error')!)
    .replaceAll('+', ' ')
    .replaceAll('%20', '!')
    .replaceAll('%21', '"')
    .replaceAll('%22', '#')
    .replaceAll('%23', '$')
    .replaceAll('%24', '%')
    .replaceAll('%25', '&')
    .replaceAll('%26', '\'')
    .replaceAll('%27', '(')
    .replaceAll('%28', ')')
    .replaceAll('%29', '*')
  : null;


const SpidAuthError: React.FunctionComponent = () => {

  // ----
  // Internal State
  // ----
  const [ errorShowed, setErrorShowed ] = React.useState<boolean>(false);
  const [ isModalOpen, setModalOpen ] = React.useState<boolean>(
    () => typeof spidErrorMessage === 'string'
  );


  // ----
  // Handlers
  // ----
  const handleModalClose = React.useCallback(
    () => {
      setModalOpen(false);
      setErrorShowed(false);
    },
    []
  );


  // ----
  // Component Render
  // ----
  return (
    <Modal
      open={isModalOpen && !errorShowed}
      actions={[
        {
          content: 'Chiudi',
          onClick: handleModalClose
        }
      ]}
      header={'Errore di Autenticazione SPID'}
      content={spidErrorMessage}
      onClose={handleModalClose}
    />
  );

};


SpidAuthError.displayName = 'SpidAuthError';

export default SpidAuthError;
