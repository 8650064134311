import * as React from 'react';

import invariant from 'tiny-invariant';

import type { AppBucketsColor } from '@appbuckets/react-ui/generic';

import type { SharedObject } from '../interfaces/generics';
import type { StateOfConservation } from '../interfaces/enums';

import { useSharedObject } from './useSharedObject';


/* --------
 * Internal Types
 * -------- */
export interface UseStateOfConservationResult extends SharedObject {
  /** Color usable into UI */
  color: AppBucketsColor;
}


/* --------
 * Static Variables
 * -------- */
const CONSERVATION_APPEARANCE: Record<number, AppBucketsColor> = {
  15: 'success',
  12: 'blue',
  9 : 'teal',
  6 : 'orange',
  3 : 'cloud dark',
  0 : 'danger'
};


/* --------
 * Hooks Definition
 * -------- */
export function useStateOfConservation(state: StateOfConservation): UseStateOfConservationResult {

  // ----
  // Internal Hooks
  // ----
  const stateOfConservation = useSharedObject('StateOfConservation');


  // ----
  // Hook Result
  // ----
  return React.useMemo<UseStateOfConservationResult>(
    () => {
      /** Transform into SharedObject */
      const sharedObject = stateOfConservation.get(state);

      /** Assert it exists */
      invariant(sharedObject, `An invalid state has been provided to useStateOfConservation hook : ${state}`);

      /** Return the Object */
      return {
        ...sharedObject,
        color: CONSERVATION_APPEARANCE[state] || 'danger'
      };
    },
    [ state, stateOfConservation ]
  );

}
