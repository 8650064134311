import * as React from 'react';

import sorter from '@appbuckets/sorter';

import { useClientState } from '@appbuckets/react-app-client';

import { BaseUserScopeProvider } from './UserScope.context';

import { useGetQuery } from '../../hooks';

import type { SharedObjectsResponse, UserScopeContext } from './UserScopeProvider.types';

import type { AccountCompleteDto, ItemTypeCategoryCompleteDto } from '../../interfaces/entities';
import { SharedObject, SharedObjectName } from '../../interfaces/generics';
import { AccountRole } from '../../interfaces/enums';


/* --------
 * Scope Component Definition
 * -------- */
const UserScopeProvider: React.FunctionComponent = (props) => {

  // ----
  // Internal Hooks
  // ----
  const {
    userData,
    hasAuth,
    isLoaded: isClientLoaded
  } = useClientState<AccountCompleteDto>();


  // ----
  // Data Query
  // ----
  const sharedObjectsQuery = useGetQuery<SharedObjectsResponse>(
    [ 'common', 'shared-objects' ],
    {
      withAccessToken : false,
      withRefreshToken: false
    },
    {
      staleTime: 60_000,
      enabled  : isClientLoaded
    }
  );

  const itemTypeCategoriesQuery = useGetQuery<ItemTypeCategoryCompleteDto[]>(
    [ 'items', 'categories' ],
    {
      withAccessToken : false,
      withRefreshToken: false
    },
    {
      staleTime: 60_000,
      enabled  : isClientLoaded
    }
  );

  const favoriteItemsQuery = useGetQuery<number[]>(
    [ 'items', 'favorites', 'simple' ],
    undefined,
    {
      enabled: hasAuth && isClientLoaded
    }
  );

  const requestedItemsQuery = useGetQuery<number[]>(
    [ 'items', 'requested', 'simple' ],
    undefined,
    {
      enabled: hasAuth && isClientLoaded
    }
  );


  // ----
  // Helpers
  // ----
  const getSharedObjects = React.useCallback(
    (name: SharedObjectName): SharedObject[] => {
      /** Assert the query has been completed before returning data */
      if (!sharedObjectsQuery.isSuccess || !Array.isArray(sharedObjectsQuery.data[name])) {
        return [];
      }

      /** Return the shared object array */
      return sharedObjectsQuery.data[name];
    },
    [ sharedObjectsQuery.data, sharedObjectsQuery.isSuccess ]
  );


  // ----
  // Memoized Data
  // ----
  const itemTypeCategories = React.useMemo(
    () => (
      sorter(itemTypeCategoriesQuery.data ?? []).orderBy('name').sort()
    ),
    [ itemTypeCategoriesQuery.data ]
  );


  // ----
  // Context Builder
  // ----
  const { id: currentUserId = 0 } = userData || {};

  const ctxValue: UserScopeContext = {
    currentUserId,
    favoritesId     : favoriteItemsQuery.data ?? [],
    getSharedObjects,
    itemTypeCategories,
    isAdmin         : userData?.role === AccountRole.Admin,
    isLoggedIn      : hasAuth,
    requestedItemsId: requestedItemsQuery.data ?? [],
    sharedObjects   : sharedObjectsQuery.data ?? null,
    userData,
    userScopeReady  : (
      isClientLoaded &&
      itemTypeCategoriesQuery.isSuccess &&
      sharedObjectsQuery.isSuccess
    )
  };


  // ----
  // Component Render
  // ----
  return (
    <BaseUserScopeProvider value={ctxValue}>
      {props.children}
    </BaseUserScopeProvider>
  );

};

UserScopeProvider.displayName = 'UserScopeProvider';

export default UserScopeProvider;
