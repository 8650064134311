import * as React from 'react';
import { useMutation } from 'react-query';

import { useClient } from '@appbuckets/react-app-client';

import { useInputValue } from '@appbuckets/react-ui';

import Modal from '@appbuckets/react-ui/Modal';
import type { ModalProps } from '@appbuckets/react-ui/Modal';

import Button from '@appbuckets/react-ui/Button';
import Input from '@appbuckets/react-ui/Input';

import { useUserScope } from '../../init/UserScopeProvider';


const EmailModal: React.FunctionComponent = () => {
  // ----
  // Internal Hooks
  // ----
  const client = useClient();
  const { userData } = useUserScope();


  // ----
  // Internal State
  // ----
  const [ email, handleEmailChange ] = useInputValue('');
  const [ code, handleCodeChange ] = useInputValue('');
  const [ isResendDisabled, setIsResendDisabled ] = React.useState(false);


  // ----
  // Internal Mutation
  // ----

  const emailMutation = useMutation(
    () => client.post('/accounts/current/set-email', { data: { email } }),
    {
      onSuccess: client.reloadUserData.bind(client)
    }
  );

  const codeMutation = useMutation(
    () => client.post(
      '/accounts/current/verify-confirmation-code',
      { data: { emailVerificationCode: code } }
    ),
    {
      onSuccess: client.reloadUserData.bind(client)
    }
  );

  const resendCodeMutation = useMutation(
    () => client.post('/accounts/current/send-verification-code'),
    {
      onSuccess: () => {
        // 30 seconds timer for avoiding to overwhelming the server with too many requests.
        setIsResendDisabled(true);
        setTimeout(() => setIsResendDisabled(false), 30000);
      }
    }
  );


  // ----
  // Memoized Data
  // ----
  const isEmailValid = React.useMemo(
    () => {
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
      return regexEmail.test(email);
    },
    [ email ]
  );

  const isCodeValid = React.useMemo(
    () => {
      const regexCode = /^\d{6}$/;
      return regexCode.test(code);
    },
    [ code ]
  );


  // ----
  // Internal Data
  // ----
  const sharedModalProps: ModalProps = {
    open              : true,
    closeIcon         : false,
    closeOnEscape     : false,
    closeOnDimmerClick: false,
    centered          : true
  };


  // ----
  // Component Render
  // ----
  if (!userData) {
    return null;
  }

  if (!userData.email) {
    return (
      <Modal
        {...sharedModalProps}
        header={'VERIFICA ACCOUNT'}
      >
        <Modal.Content>
          <p>Per continuare ad utilizzare il tuo account è necessario associare un'email.</p>
          <Input
            placeholder={'Inserisci la tua email'}
            value={email}
            onChange={handleEmailChange}
            type={'email'}
          />
        </Modal.Content>
        <Modal.Actions textAlign={'right'}>
          <Button
            primary
            content={'Conferma'}
            disabled={!isEmailValid || emailMutation.isLoading}
            onClick={() => emailMutation.mutate()}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  if (!userData.emailVerified) {
    return (
      <Modal
        {...sharedModalProps}
        header={'INSERISCI IL CODICE'}
      >
        <Modal.Content>
          <p className={'warning'}>Abbiamo inviato un codice di verifica alla tua email. <br />
            Inseriscilo qui sotto per confermare.</p>
          <Input
            placeholder={'Inserisci il codice numerico'}
            value={code}
            onChange={handleCodeChange}
            type={'text'}
            maxLength={6}
          />

          <p className={'resend-code'} style={{ marginTop: '2em' }}>
            Non hai ricevuto l'email? <br />
            Clicca
            <a
              onClick={() => !isResendDisabled && resendCodeMutation.mutateAsync()}
              style={{ pointerEvents: isResendDisabled ? 'none' : 'auto', color: isResendDisabled ? 'gray' : '' }}
            >
              {' '}qui
            </a> per richiedere un altro codice.
          </p>
        </Modal.Content>
        <Modal.Actions textAlign={'right'}>
          <Button
            primary
            content={'Verifica Codice'}
            disabled={!isCodeValid || codeMutation.isLoading}
            onClick={() => codeMutation.mutate()}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  return null;
};

export default EmailModal;
