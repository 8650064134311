import * as React from 'react';

import qs from 'qs';

import { AnyObject } from '@appbuckets/react-ui-core';

import { useHistory, useLocation } from 'react-router-dom';


/* --------
 * Function Utilities
 * -------- */
export function parseQueryString<Data>(search: string): Data {
  return qs.parse(search, {
    parseArrays: true
  }) as any as Data;
}

export function stringifyQueryString<Data extends AnyObject>(data: Data): string {
  return qs.stringify(data, {
    arrayFormat: 'indices',
    skipNulls  : true
  });
}


export function useQueryString<Data extends AnyObject>(): [ Data, (data: Data | ((data: Data) => Data)) => void ] {
  // ----
  // Internal Hook
  // ----
  const history = useHistory();
  const { search, pathname } = useLocation();

  const currentSearchParams = React.useMemo(
    () => parseQueryString<Data>(search.replace(/^\?/, '')),
    [ search ]
  );

  const setQueryString = React.useCallback(
    (data: Data | ((data: Data) => Data)) => {
      /** Stringify Query String */
      const stringifyData = stringifyQueryString(typeof data === 'function' ? data(currentSearchParams) : data);

      /** Change location */
      history.push({
        pathname,
        search: `?${stringifyData}`
      });
    },
    [ history, pathname, currentSearchParams ]
  );

  return [ currentSearchParams, setQueryString ];
}
