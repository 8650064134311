import * as React from 'react';

import DropdownMenu from '@appbuckets/react-ui/DropdownMenu';
import type { DropdownMenuProps } from '@appbuckets/react-ui/DropdownMenu';

import { useClientState } from '@appbuckets/react-app-client';

import type { AccountCompleteDto } from '../../../../interfaces/entities';
import { AccountRole } from '../../../../interfaces/enums';

import { AppLink } from '../../../index';


const AdminDropdownMenu: React.VoidFunctionComponent<DropdownMenuProps> = (props) => {

  // ----
  // Internal Hooks
  // ----
  const { hasAuth, userData } = useClientState<AccountCompleteDto>();


  // ----
  // Component Render
  // ----
  if (!hasAuth || !userData || userData.role < AccountRole.Admin) {
    return null;
  }

  return (
    <DropdownMenu
      {...props}
      items={[
        { key: 1, icon: 'check-circle', as: AppLink, to: 'ApproveItem', content: 'Approvazione Oggetti' },
        // { key: 2, icon: 'sitemap', as: AppLink, to: 'ManageCategories', content: 'Gestisci Categorie' },
        { key: 3, icon: 'users-cog', as: AppLink, to: 'ManageUsers', content: 'Gestisci Utenti' }
      ]}
    />
  );

};

AdminDropdownMenu.displayName = 'AdminDropdownMenu';

export default AdminDropdownMenu;
