import * as React from 'react';

import { isNil } from '@appbuckets/utils';

import objectHash from 'object-hash';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useClient } from '@appbuckets/react-app-client';
import type { ClientRequestParams } from '@appbuckets/react-app-client';


/* --------
 * Component Definition
 * -------- */
const ReactQueryProvider: React.FunctionComponent = (props) => {

  // ----
  // Extract Children
  // ----
  const { children } = props;


  // ----
  // Internal Hooks and Data
  // ----
  const isFirstMount = React.useRef(true);
  const client = useClient();


  // ----
  // Query Client Builder
  // ----
  const buildQueryClient = React.useCallback(
    () => new QueryClient({
      defaultOptions: {
        /** Set defaults query options */
        queries: {
          /** Query Refetch Option */
          refetchOnMount      : true,
          refetchOnReconnect  : true,
          refetchOnWindowFocus: true,

          /** Extend the function to check if data has been change */
          isDataEqual: (oldData, newData) => {
            /** Check if old data is nil */
            if (isNil(oldData)) {
              return isNil(newData);
            }

            /** Check if new data is nil */
            if (isNil(newData)) {
              return isNil(oldData);
            }

            /** If they are boot object, then check hash */
            if (typeof oldData === 'object' && typeof newData === 'object') {
              return objectHash(oldData) === objectHash(newData);
            }

            /** Return base data equality check */
            return oldData === newData;
          },

          /** Set a default query function */
          queryFn: (ctx) => {
            /** Get current Query Key */
            const { queryKey } = ctx;

            /** Build URL using query Key */
            const urlPart: (string | number)[] = [];
            let params: Omit<ClientRequestParams, 'data'> | undefined = undefined;

            /** Split query key */
            queryKey.forEach((key: any, ix, array) => {
              /** If key is string | number use to build the url */
              if (typeof key === 'string' || typeof key === 'number') {
                urlPart.push(key);
              }

              /** Last object could be params for request */
              if (ix === array.length - 1 && typeof key === 'object' && key !== null) {
                params = key as Omit<ClientRequestParams, 'data'>;
              }
            });

            /** Return the client main function used to get data */
            return client.get(urlPart.join('/'), params);
          }
        }
      }
    }),
    [ client ]
  );


  // ----
  // Query Client Builder
  // ----
  const [ queryClient, setQueryClient ] = React.useState<QueryClient>(buildQueryClient);


  // ----
  // Query Client Updater
  // ----
  React.useEffect(
    () => {
      /** Avoid first mount useless update */
      if (isFirstMount.current) {
        isFirstMount.current = false;
        return;
      }

      /** Rebuild the Query Client */
      window.console.log('[ !! ] - Query Client Rebuild');
      setQueryClient(buildQueryClient());
    },
    [ buildQueryClient ]
  );


  // ----
  // Component Render
  // ----
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );

};

ReactQueryProvider.displayName = 'ReactQueryProvider';

export default ReactQueryProvider;
