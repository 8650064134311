import { useClient } from '@appbuckets/react-app-client';

/* --------
 * Constant Definition
 * -------- */
const LOGIN_ACTION = 'login';
const LOGOUT_ACTION = 'logout';

const SPID_PROXY_URL = 'https://spid.bachecadelriutilizzo.ccs.to.it/proxy.php';
const SPID_CLIENT_ID = 'ccs-spid-client';

const CIE_PROXY_URL = 'https://cie.ccs.to.it/proxy.php';
const CIE_CLIENT_ID = '6491a3356ea6f';

const LAND_HOSTNAME = process.env.NODE_ENV === 'development' ? 'localhost:5001' : 'api.bachecadelriutilizzo.ccs.to.it';


/* --------
 * Internal Types
 * -------- */
interface UseUrlReturn {
  /** Url to use to perform login action on SPID */
  spidLogin: string;

  /** Url to use to perform logout action on SPID */
  spidLogout: string;

  /** Url to use to perform login action on CIE */
  cieLogin: string;

  /** Url to use to perform logout action on CIE */
  cieLogout: string;
}


/* --------
 * Helpers
 * -------- */
function buildSpidUrl(action: string, baseUrl: string, endpoint: string): string {
  const spidUrl = new URL(SPID_PROXY_URL);
  spidUrl.searchParams.append('client_id', SPID_CLIENT_ID);
  spidUrl.searchParams.append('action', action);
  spidUrl.searchParams.append(
    'redirect_uri',
    `https://${LAND_HOSTNAME}/v1/auth/${endpoint}`
  );

  return spidUrl.toString();
}

function buildCIEUrl(action: string, baseUrl: string, endpoint: string): string {
  const cieUrl = new URL(CIE_PROXY_URL);
  cieUrl.searchParams.append('client_id', CIE_CLIENT_ID);
  cieUrl.searchParams.append('action', action);
  cieUrl.searchParams.append('idp', 'CIE');
  cieUrl.searchParams.append(
    'redirect_uri',
    `https://api.bachecadelriutilizzo.ccs.to.it/v1/auth/${endpoint}`
  );

  return cieUrl.toString();
}

/* --------
 * Hook Definition
 * -------- */
export function useUrl(): UseUrlReturn {

  // ----
  // Internal Hook
  // ----
  const client = useClient();


  // ----
  // Hook Return
  // ----
  return {
    spidLogin : buildSpidUrl(LOGIN_ACTION, client.baseUrl, 'spid-landing'),
    spidLogout: buildSpidUrl(LOGOUT_ACTION, client.baseUrl, 'spid-logout'),
    cieLogin: buildCIEUrl(LOGIN_ACTION, client.baseUrl, 'cie-landing'),
    cieLogout: buildCIEUrl(LOGOUT_ACTION, client.baseUrl, 'cie-logout'),
  };

}
