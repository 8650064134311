import * as React from 'react';
import * as ReactDOM from 'react-dom';


/* --------
 * Import Icons Library
 * -------- */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons/faHeart';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons/faUserCircle';


/* --------
 * Set DayJS and JSTimeAgo internalization
 * -------- */
import dayjs from 'dayjs';
import 'dayjs/locale/it';

import JavascriptTimeAgo from 'javascript-time-ago';
import it from 'javascript-time-ago/locale/it.json';


/* --------
 * Set YUP Locale
 * -------- */
import { it as yupItLocale } from 'yup-locales';
import * as Yup from 'yup';


/* --------
 * Style Loader
 * -------- */
import './assets/styles/index.scss';


/* --------
 * Import main App and the App Providers
 * -------- */
import AppProviders from './app/init';

import ReuseBoardApp from './app';


/* --------
 * Import System Customization
 * -------- */
import './app/system/customization';


/* --------
 * Configure Dependencies
 * -------- */
dayjs.locale('it');
JavascriptTimeAgo.addDefaultLocale(it);
Yup.setLocale(yupItLocale);
library.add(fas, faHeart, faUserCircle);


/* --------
 * Render the App
 * -------- */
ReactDOM.render(
  <AppProviders>
    <ReuseBoardApp />
  </AppProviders>,
  document.getElementById('root')
);
