import * as React from 'react';

import dayjs from 'dayjs';
import qs from 'qs';

import { Client, ClientProvider as BaseClientProvider } from '@appbuckets/react-app-client';
import type { AccessToken } from '@appbuckets/react-app-client';

import { isValidString } from '@appbuckets/utils';

import type { AccountCompleteDto } from '../../interfaces/entities';

import type { ClientStorage, ApiTokenData, AuthResponse } from './ClientProvider.types';


/* --------
 * Client Init
 * -------- */
const client = Client.getInstance<AccountCompleteDto, ClientStorage>({
  initialStore: {},

  /** Set up Auth Configuration */
  auth: {
    /** Access Token will be considered expired 1 minute earlier */
    accessTokenValidityThreshold: 60_000,
    /** Access Token will be sent through the Bearer Authorization Header */
    accessTokenAsBearerToken: true,
    /** Set the persistent field into save accessToken information */
    accessTokenStorageField: 'accessToken',
    /** The query param name to use to send refresh token to grant Access Token */
    refreshTokenQueryParamName: 'refreshToken',
    /** Set the persistent field into save refreshToken information */
    refreshTokenStorageField: 'refreshToken',
    /** Set the user data storage field */
    userDataStorageField: 'accountData',
    /** Extract AccessToken from signin and signup */
    extractAccessTokenFromAuthResponse: (authResponse: AuthResponse) => ({
      email    : authResponse.account.email,
      expiresAt: dayjs(authResponse.accessToken.expiresAt).valueOf(),
      token    : authResponse.accessToken.token,
      roles    : []
    }),
    /** Extract the RefreshToken from Auth Response */
    extractRefreshTokenFromAuthResponse: (authResponse: AuthResponse) => authResponse.refreshToken.token,
    /** Enable the loading of the refresh token from query params */
    extractRefreshTokenFromQueryParams: 'token',
    /** Remove the token only on safari */
    removeExtractRefreshTokenQueryParam: !/safari/i.test(window.navigator.userAgent),
    /** Extract userdata from auth response */
    extractUserDataFromAuthResponse: (authResponse: AuthResponse) => authResponse.account
  },

  /** Set up debug */
  debug: {
    enabled : {
      development: true,
      production : false
    },
    logLevel: {
      development: 'debug'
    }
  },

  /** Configure localDatabase */
  localDb: {
    driver     : 'LOCALSTORAGE',
    description: 'ReuseBoard to store Persistent Data',
    name       : 'ReuseBoardApp',
    storeName  : 'ReuseBoardPersistent',
    version    : 3.0
  },

  /** Set system request data */
  requests: {
    axiosConfig: {
      paramsSerializer: (params) => qs.stringify(params, {
        serializeDate: (date) => dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
      })
    },
    domain     : {
      development: 'localhost',
      production : 'api.bachecadelriutilizzo.ccs.to.it'
    },
    port       : {
      development: 5001,
      production : 80
    },
    secure     : true,
    timeout    : {
      development: 120_000,
      production : 30_000
    }
  },

  /** Set up base API */
  api: {
    /** Get userData, using AccessToken */
    getUserData: {
      method         : 'GET',
      url            : '/auth/who-am-i',
      withAccessToken: true
    },

    loginWithUsernameAndPassword: (thisClient, email, password) => ({
      method          : 'POST',
      url             : '/auth/email-login',
      withAccessToken : false,
      withRefreshToken: false,
      data            : {
        username: email,
        password
      }
    }),

    /** Grant new AccessToken using RefreshToken */
    grantAccessToken: (thisClient) => ({
      method            : 'GET',
      url               : '/auth/refresh',
      withAccessToken   : false,
      withRefreshToken  : true,
      axiosRequestConfig: {
        transformResponse: (res?: string): AccessToken => {
          /** Assert response is valid string */
          if (!isValidString(res)) {
            throw new Error('Invalid AccessToken Response');
          }

          /** Parse as token data */
          const parsedResponse = JSON.parse(res) as ApiTokenData;

          /** Return data */
          return {
            token    : parsedResponse.token,
            email    : thisClient.state.userData?.email || '',
            expiresAt: dayjs(parsedResponse.expiresAt).valueOf(),
            roles    : []
          };
        }
      }
    })
  }
});


/* --------
 * Main Provider Export
 * -------- */
const ClientProvider: React.FunctionComponent = (props) => {

  // ----
  // Extract Children
  // ----
  const { children } = props;


  // ----
  // Component Render
  // ----
  return (
    <BaseClientProvider value={client}>
      {children}
    </BaseClientProvider>
  );
};

ClientProvider.displayName = 'ClientProvider';

export default ClientProvider;
