export enum NotificationType {
  Custom,

  NewItemToApprove,

  WishlistItemAdded,

  ItemAccepted,

  ItemRejected,

  NewBookRequest,

  BookRequestAccepted,

  BookRequestRejected
}
