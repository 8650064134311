import * as React from 'react';

import { isValidString } from '@appbuckets/utils';

import { useClient } from '@appbuckets/react-app-client';


/* --------
 * Internal Types
 * -------- */
type PublicNamespace = 'profiles' | 'messages' | 'public';


/* --------
 * Hook Definition
 * -------- */
export function usePublicUrl(defaultNamespace: PublicNamespace) {

  // ----
  // Internal Hook
  // ----
  const client = useClient();


  // ----
  // Get Client Server URL
  // ----
  const baseUrl = (client as any).client.defaults.baseURL;


  // ----
  // Return a Function to build Public Url
  // ----
  return React.useCallback(
    (filename: string | null | undefined, overrideNamespace?: PublicNamespace): string | null => {
      if (isValidString(filename)) {
        return `${baseUrl}/uploads/${overrideNamespace || defaultNamespace}/${filename}`;
      }

      return null;
    },
    [ defaultNamespace, baseUrl ]
  );
}
