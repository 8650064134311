import * as React from 'react';

import { useUserScope } from '../init/UserScopeProvider';

import type { SharedObjectName, SharedObject } from '../interfaces/generics';


/* --------
 * Internal Types
 * -------- */
export interface UseSharedObjectResult {
  /** Get a shared object using its value */
  get: (value?: number | string | null) => SharedObject | undefined;

  /** Get all values from Shared Object */
  values: SharedObject[];
}


/* --------
 * Hook definition
 * -------- */
export function useSharedObject(name: SharedObjectName): UseSharedObjectResult {

  /** Get the shared objects getter */
  const { getSharedObjects } = useUserScope();

  /** Define the getter utility */
  const extractSharedObject = React.useCallback(
    (sharedObjects: SharedObject[], value: number | string): SharedObject | undefined => (
      sharedObjects.find((sharedObject) => (
        typeof value === 'number'
          ? sharedObject.value === value
          : sharedObject.name === value
      ))
    ),
    []
  );

  return React.useMemo(
    () => {
      /** Get all shared objects */
      const values = getSharedObjects(name);

      /** Return hook result */
      return {
        get: (value) => (
          typeof value === 'number' || typeof value === 'string'
            ? extractSharedObject(values, value)
            : undefined
        ),
        values
      };
    },
    [ extractSharedObject, getSharedObjects, name ]
  );

}
