import * as React from 'react';

import ClientProvider from './ClientProvider';
import ReactQueryProvider from './ReactQueryProvider';
import ReactUITheme from './ReactUITheme';
import UserScopeProvider from './UserScopeProvider';


/* --------
 * Component Definition
 * -------- */
const AppProviders: React.FunctionComponent = (props) => (
  <ClientProvider>
    <ReactUITheme>
      <ReactQueryProvider>
        <UserScopeProvider>
          {props.children}
        </UserScopeProvider>
      </ReactQueryProvider>
    </ReactUITheme>
  </ClientProvider>
);

AppProviders.displayName = 'AppProviders';

export default AppProviders;
