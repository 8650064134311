import * as React from 'react';

import Button from '@appbuckets/react-ui/Button';
import Icon from '@appbuckets/react-ui/Icon';

import { useUserScope } from '../../../init/UserScopeProvider';

import { AppLink } from '../../index';
import type { PageComponent } from '../../index';


import AdminDropdownMenu from './atoms/AdminDropdownMenu';
import NotificationButton from './atoms/NotificationsButton';

import UserDropdownMenu from './atoms/UserDropdownMenu';

import logo from '../../../../assets/img/logo-small.png';


// ----
// Component Render
// ----
const Navbar: PageComponent = (props) => {

  // ----
  // Props Deconstruct
  // ----
  const {
    appState: {
      userHasAuth
    }
  } = props;


  // ----
  // Internal Hooks
  // ----
  const { userData } = useUserScope();


  // ----
  // Component Render
  // ----
  return (
    <header>
      <nav>
        <div className={'navbar-content phone'}>
          <AppLink to={'Home'}>
            <div className={'navbar-item'}>
              <Icon name={'home'} />
              <span>Home</span>
            </div>
          </AppLink>
          <AppLink to={'DonateItem'}>
            <div className={'navbar-item'}>
              <Icon name={'plus'} />
              <span>Inserisci</span>
            </div>
          </AppLink>
          {userHasAuth && (
            <AdminDropdownMenu
              inverted
              trigger={(
                <div className={'navbar-item'}>
                  <Icon name={'tools'} />
                  <span>Amministratore</span>
                </div>
              )}
            />
          )}
          <UserDropdownMenu
            trigger={(
              <div className={'navbar-item'}>
                <Icon name={'user-circle'} iconStyle={'far'} />
                <span>Tu</span>
              </div>
            )}
          />
          {!userHasAuth && (
            <div>
              <AppLink to={'Login'}>
                <Button primary>Entra</Button>
              </AppLink>
            </div>
          )}
        </div>
        <div className={'navbar-content no-phone'}>
          <div className={'app-logo'}>
            <AppLink to={'Home'}>
              <img src={logo} alt={'App logo'} width={'auto'} />
            </AppLink>
          </div>
          <div className={'app-actions'}>
            {!userHasAuth && (
              <div>
                <AppLink to={'Login'}>
                  <Button
                    primary
                    content={'Entra'}
                    icon={<Icon iconStyle={'fas'} name={'user'} />}
                  />
                </AppLink>
              </div>
            )}
            {userHasAuth && userData && (
              <React.Fragment>
                <NotificationButton />
                <AdminDropdownMenu
                  inverted
                  trigger={(
                    <Button warning icon={'tools'} />
                  )}
                />
                <UserDropdownMenu
                  trigger={(
                    <Button
                      icon={{ name: 'user-circle', iconStyle: 'far' }}
                      content={userData.firstName}
                    />
                  )}
                />
                <Button
                  success
                  as={AppLink}
                  to={'DonateItem'}
                  icon={'plus'}
                  content={'Inserisci'}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

Navbar.displayName = 'Navbar';

export default Navbar;
