import * as React from 'react';

import type { AppRoute } from './index';


/* --------
 * Shared Route Props
 * -------- */
const sharedPublicRoutesProps: Partial<AppRoute> = {
  hasSidebar: false,
  hasNavbar : true,
  hasFooter : true,
  isPrivate : true,
  isPublic  : true,
  exact     : true
};

const sharedPrivateRoutesProps: Partial<AppRoute> = {
  hasSidebar: false,
  hasNavbar : true,
  hasFooter : true,
  isPrivate : true,
  isPublic  : false,
  exact     : true
};


/* --------
 * Define App Main Routes
 * -------- */
const routes: AppRoute[] = [

  {
    ...sharedPublicRoutesProps,
    name     : 'Home',
    path     : `${process.env.PUBLIC_URL}/`,
    component: React.lazy(() => import('../pages/Home')),
    title    : 'Benvenuto',
    isDefault: true
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'Login',
    path     : `${process.env.PUBLIC_URL}/login`,
    component: React.lazy(() => import('../pages/Login')),
    title    : 'Entra',
    isPrivate: false
  },


  // ----
  // Items Pages
  // ----
  {
    ...sharedPublicRoutesProps,
    name     : 'ItemsSearch',
    path     : `${process.env.PUBLIC_URL}/items`,
    component: React.lazy(() => import('../pages/ItemsSearch')),
    title    : 'Ricerca Oggetti',
    routes   : [
      {
        name     : 'ItemSingle',
        path     : '/:id',
        component: React.lazy(() => import('../pages/ItemSingle'))
      }
    ]
  },

  {
    ...sharedPrivateRoutesProps,
    name     : 'DonateItem',
    path     : `${process.env.PUBLIC_URL}/items/donate`,
    component: React.lazy(() => import('../pages/DonateItem')),
    title    : 'Inserisci Oggetto'
  },

  {
    ...sharedPrivateRoutesProps,
    name     : 'ItemsMine',
    path     : `${process.env.PUBLIC_URL}/items/mine`,
    component: React.lazy(() => import('../pages/ItemsMine')),
    title    : 'I miei Oggetti'
  },

  {
    ...sharedPrivateRoutesProps,
    name     : 'FavoriteItems',
    path     : `${process.env.PUBLIC_URL}/items/favorite`,
    component: React.lazy(() => import('../pages/ItemsFavorite')),
    title    : 'Preferiti'
  },


  // ----
  // Book Requests Pages
  // ----
  {
    ...sharedPrivateRoutesProps,
    name     : 'BookRequests',
    path     : `${process.env.PUBLIC_URL}/book-requests/mine`,
    component: React.lazy(() => import('../pages/BookRequests')),
    title    : 'Richieste di Ritiro'
  },


  // ----
  // Wishlists Page
  // ----
  {
    ...sharedPrivateRoutesProps,
    name     : 'Wishlists',
    path     : `${process.env.PUBLIC_URL}/wishlists`,
    component: React.lazy(() => import('../pages/Wishlists')),
    title    : 'Liste dei Desideri'
  },


  // ----
  // Notifications
  // ----
  {
    ...sharedPrivateRoutesProps,
    name     : 'Notifications',
    path     : `${process.env.PUBLIC_URL}/notifications`,
    component: React.lazy(() => import('../pages/Notifications')),
    title    : 'Notifiche'
  },


  // ----
  // Backoffice Pages
  // ----
  {
    ...sharedPrivateRoutesProps,
    name     : 'ApproveItem',
    path     : `${process.env.PUBLIC_URL}/admin/approve-items`,
    component: React.lazy(() => import('../pages/Admin/ApproveItems')),
    title    : 'Approvazione Oggetti'
  },

  {
    ...sharedPrivateRoutesProps,
    name     : 'ManageUsers',
    path     : `${process.env.PUBLIC_URL}/admin/manage-users`,
    component: React.lazy(() => import('../pages/Admin/ManageUsers')),
    title    : 'Gestisci Utenti'
  }

];


/* --------
 * Routes Export
 * -------- */
export default routes;
