import * as React from 'react';

import Badge from '@appbuckets/react-ui/Badge';
import Button from '@appbuckets/react-ui/Button';
import Icon from '@appbuckets/react-ui/Icon';

import { useQuery } from 'react-query';

import { useAppRouter } from '../../../index';


/* --------
 * Internal Props
 * -------- */
interface UnreadNotificationCountResponse {
  count: number;
}


/* --------
 * Component Definition
 * -------- */
const NotificationButton: React.VoidFunctionComponent = () => {

  // ----
  // Internal Query
  // ----
  const unreadNotificationQuery = useQuery<UnreadNotificationCountResponse>([ 'notifications', 'unread', 'count' ], {
    refetchInterval: 10_000
  });


  // ----
  // Internal Hooks
  // ----
  const { routeTo } = useAppRouter();


  // ----
  // Memoized Data
  // ----
  const unreadCount = React.useMemo(
    () => {
      /** Assert query has success */
      if (unreadNotificationQuery.status !== 'success') {
        return 0;
      }

      /** Return the total count */
      return unreadNotificationQuery.data.count;
    },
    [ unreadNotificationQuery.data, unreadNotificationQuery.status ]
  );


  // ----
  // Handlers
  // ----
  const handleButtonClick = React.useCallback(
    () => routeTo('Notifications'),
    [ routeTo ]
  );


  // ----
  // Component Render
  // ----
  return (
    <Button fitted onClick={handleButtonClick} tooltip={'Notifiche'}>
      <Icon className={'m-0'} name={'bell'} />
      {!!unreadCount && <Badge content={unreadCount.toString()} />}
    </Button>
  );
};

NotificationButton.displayName = 'NotificationButton';

export default NotificationButton;
