import * as React from 'react';

import { Loader } from '@appbuckets/react-ui';


export default () => (
  <div className={'full-page-loader'}>
    <Loader inline type={'circular dots'} appearance={'white shade'} size={'big'} />
  </div>
);
